import React from 'react'
import { Link } from 'gatsby'
import { T } from '../helpers/i18n'
import * as styles from '../styles/modules/footer.module.css'
import { currentLang } from '../helpers/i18n'

const Footer = ({ menuItems }) => {
  let { lang } = currentLang()
  menuItems = menuItems.filter((m) => m.node_locale === lang)
  return (
    <footer>
      <div className="mb-5">
        <h1 className="text-primary text-center my-3">{T('GO_TO')}</h1>
        <div className="text-center flex justify-center sm:block">
          <a
            className={styles.anchorWeb}
            target="_blank"
            rel="noreferrer"
            href="https://www.werkenbijaldautomotive.nl"
          >
            {T('ALD_NL')}
          </a>
          <a
            className={styles.anchorWeb}
            target="_blank"
            rel="noreferrer"
            href="https://jobs.aldautomotive.lu"
          >
            {T('ALD_LU')}
          </a>
        </div>
      </div>
      <div className={styles.preFooter}>
        <span className="mr-6 mb-2 sm:mb-0">
          © Copyright {new Date().getFullYear()}
        </span>
        {menuItems.map((item) => (
          <span className="mr-3" key={item.node_locale + item.name}>
            {item.url.startsWith('/') ? (
              <Link to={`${item.url !== null ? item.url : ''}`}>
                <span className={styles.navigationItem}>{item.name}</span>
              </Link>
            ) : (
              <a target="_blank" rel="noreferrer" href={item.url}>
                <span className={styles.navigationItem}>{item.name}</span>
              </a>
            )}
          </span>
        ))}
      </div>
      <div className={styles.greyWrapper}>
        <div className={styles.container}>
          <div>
            <img
              className={styles.logoSociete}
              height="40"
              width="77"
              src="/images/societe.svg"
              alt="Societe Generale Logo"
            />
          </div>
          <div>
            <div className="flex">
              <a
                href="https://www.linkedin.com/company/ald-automotive-belgium/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  height="28"
                  width="28"
                  src="/images/linkedin.png"
                  alt="LinkedIn"
                />
              </a>
              <a
                href="https://www.facebook.com/aldautomotivebelgium/"
                target="_blank"
                rel="noreferrer"
                className="ml-3"
              >
                <img
                  height="28"
                  width="28"
                  src="/images/facebook.png"
                  alt="Facebook"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
