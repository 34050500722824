import { getCurrentLangKey } from 'ptz-i18n'
import languages from '../../static/languages.json'
import fr from '../../static/i18n/fr.json'
import nl from '../../static/i18n/nl.json'

function currentLang() {
  let loc = { pathname: null }
  let lang = null

  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    loc = location // eslint-disable-line
    lang = getCurrentLangKey(
      languages.allLanguages,
      languages.defaultLanguage,
      loc.pathname
    )
  }
  return { lang, loc }
}

function T(translationKey) {
  let { lang } = currentLang()
  if (lang === 'fr-BE' && fr[translationKey] !== undefined) {
    return fr[translationKey]
  } else if (lang === 'nl-BE' && nl[translationKey] !== undefined) {
    return nl[translationKey]
  }
  return translationKey
}

export { currentLang, T }
