import React from 'react'
import { Link } from 'gatsby'
import { getCurrentLangKey } from 'ptz-i18n'
import languages from '../../../static/languages.json'

function LanguageSelector({ menuItems, location, className }) {
  let lang
  if (location.pathname !== null) {
    lang = getCurrentLangKey(
      languages.allLanguages,
      languages.defaultLanguage,
      location.pathname
    )
  } else {
    return <div>Loading language options...</div>
  }
  const tPathname = (pathname) => {
    const langToSearch = lang
    const langToRetrieve =
      lang === languages.defaultLanguage
        ? languages.secondaryLanguage
        : languages.defaultLanguage

    // Find code in language to search
    let code = menuItems
      .filter((m) => m.node_locale === langToSearch)
      .find((m) => m.url === decodeURI(pathname)).code

    // Retrieve code from the other language
    return menuItems
      .filter((m) => m.node_locale === langToRetrieve)
      .find((m) => m.code === code).url
  }
  return (
    <>
      <Link
        to={
          lang === languages.secondaryLanguage
            ? location.pathname
            : tPathname(location.pathname)
        }
      >
        <img
          src="/images/nl.png"
          alt="nl"
          width="22"
          height="15"
          style={{ width: '22px', height: '15px' }}
        />
      </Link>
      <span className="mx-2"></span>
      <Link
        to={
          lang === languages.defaultLanguage
            ? location.pathname
            : tPathname(location.pathname)
        }
      >
        <img
          src="/images/fr.png"
          alt="fr"
          width="22"
          height="15"
          style={{ width: '22px', height: '15px' }}
        />
      </Link>
    </>
  )
}
export default LanguageSelector
