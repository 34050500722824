import React from 'react'

import '../../styles/variables.css'
import '../../styles/global.css'
import Seo from './seo'
import Footer from '../footer'
import Navigation from '../navigation'
import Cookies from '../cookies'

class Template extends React.Component {
  render() {
    const { children, menuItems, cookies } = this.props

    return (
      <>
        <Seo />
        <Navigation menuItems={menuItems} />
        <main>{children}</main>
        <Footer menuItems={menuItems} />
        <Cookies cookies={cookies} />
      </>
    )
  }
}

export default Template
