import React from 'react'
import { Link } from 'gatsby'
import languages from '../../static/languages.json'
import LanguageSelector from './partials/language-selector.js'
import * as styles from '../styles/modules/navigation.module.css'
import { currentLang } from '../helpers/i18n'

const Navigation = ({ menuItems }) => {
  let { lang, loc } = currentLang()
  const filteredMenuItems = menuItems.filter((m) => m.node_locale === lang)

  let [mobileMenuActive, setMobileMenuActive] = React.useState(false)
  const toggleMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive)
  }

  return (
    <>
      {/* Desktop */}
      <div className="hidden md:block">
        <nav role="navigation" className={styles.container} aria-label="Main">
          <div>
            <Link to={lang !== languages.defaultLanguage ? `/${lang}` : '/'}>
              <img
                className={styles.logo}
                src="/images/logo.png"
                alt="ALD Logo"
                width={186}
                height={79}
              />
            </Link>
          </div>
          <div className={styles.lang}>
            <LanguageSelector menuItems={menuItems} location={loc} />
          </div>
        </nav>
        <div className={styles.navigation}>
          {filteredMenuItems.map((item) =>
            item.url.startsWith('/') ? (
              <Link
                to={`${item.url !== null ? item.url : ''}`}
                activeClassName="active"
                key={loc + item.code}
              >
                <span className={styles.navigationItem}>{item.name}</span>
              </Link>
            ) : (
              <a
                target="_blank"
                rel="noreferrer"
                href={item.url}
                key={loc + item.code}
              >
                <span className={styles.navigationItem}>{item.name}</span>
              </a>
            )
          )}
        </div>
      </div>
      {/* Mobile */}
      <div className="md:hidden">
        <nav
          role="navigation"
          className={styles.container_mobile}
          aria-label="Main"
        >
          <div>
            <Link to={lang !== languages.defaultLanguage ? `/${lang}` : '/'}>
              <img
                className={styles.logo_mobile}
                src="/images/logo.png"
                alt="ALD Logo"
                width={122}
                height={52}
              />
            </Link>
          </div>
          <div className={styles.lang}>
            <LanguageSelector menuItems={menuItems} location={loc} />
          </div>
          <div className={styles.navigation_mobile}>
            <button
              className="md:hidden ml-3"
              onClick={() => toggleMobileMenu()}
            >
              <img
                className={styles.mobileMenuTrigger}
                height="40"
                width="25"
                src="/images/mobile-trigger.svg"
                alt="Mobile Menu Trigger"
              />
            </button>
            <ul className={mobileMenuActive ? styles.mobileMenu : 'hidden'}>
              {filteredMenuItems.map((item) =>
                item.url.startsWith('/') ? (
                  <Link
                    to={`${item.url !== null ? item.url : ''}`}
                    className="py-6 border-y border-tertiary"
                    activeClassName="active"
                    key={loc + item.code}
                  >
                    <li>{item.name}</li>
                  </Link>
                ) : (
                  <a
                    className="py-6 border-y border-tertiary"
                    target="_blank"
                    rel="noreferrer"
                    href={item.url}
                    key={loc + item.code}
                  >
                    {item.name}
                  </a>
                )
              )}
            </ul>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navigation
