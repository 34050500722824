import React from 'react'

import * as styles from '../styles/modules/cookies.module.css'

class Cookies extends React.Component {
  componentDidMount() {
    if (localStorage.getItem('closedCookieBanner') === 'true') {
      document.getElementById('cookies').style.display = 'none'
    }
  }

  render() {
    const { cookies } = this.props

    const acceptClose = () => {
      document.getElementById('cookies').style.display = 'none'
      localStorage.setItem('closedCookieBanner', 'true')
    }

    return (
      <div id="cookies" className={styles.cookiesWrapper}>
        <div>
          <div className="font-bold">{cookies.title}</div>
          <div className="mr-4">
            {cookies.content}{' '}
            <a className="underline" href={cookies.linkUrl}>
              {cookies.linkName}
            </a>
          </div>
        </div>
        <button
          className={styles.cookieClose}
          onClick={acceptClose}
          onKeyPress={acceptClose}
        >
          X
        </button>
      </div>
    )
  }
}

export default Cookies
