// extracted by mini-css-extract-plugin
export var container = "navigation-module--container--ky48S";
export var container_mobile = "navigation-module--container_mobile--e3g3h";
export var navigation = "navigation-module--navigation--SmQpI";
export var navigation_mobile = "navigation-module--navigation_mobile--diRsy";
export var mobileMenuTrigger = "navigation-module--mobileMenuTrigger--F4LOs";
export var mobileMenu = "navigation-module--mobileMenu--FmcxW";
export var navigationItem = "navigation-module--navigationItem--S5WsN";
export var lang = "navigation-module--lang--Z2qzp";
export var logoLink = "navigation-module--logoLink--9eJY6";
export var logo = "navigation-module--logo--EARHP";
export var logo_mobile = "navigation-module--logo_mobile--M9F+L";